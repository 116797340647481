import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  VENDOR_POLICIES_FEATURE_KEY,
  VendorPoliciesState,
} from './vendor-policies.reducer';

export const selectPoliciesState = createFeatureSelector<VendorPoliciesState>(
  VENDOR_POLICIES_FEATURE_KEY,
);

export const selectPolicies = createSelector(
  selectPoliciesState,
  (state: VendorPoliciesState) => state.policies,
);
