import { createReducer, on } from '@ngrx/store';
import * as Actions from './vendor-policies.actions';
import { VendorPolicy } from '../policy.model';

export const VENDOR_POLICIES_FEATURE_KEY = 'vendorPolicies';

export interface VendorPoliciesState {
  policies: { [key: string]: VendorPolicy[] };
}

export interface VendorPoliciesPartialState {
  readonly [VENDOR_POLICIES_FEATURE_KEY]: VendorPoliciesState;
}

export const initialState: VendorPoliciesState = {
  policies: {},
};

const reducePolicies = (
  items: VendorPolicy[],
  entities: { [key: string]: VendorPolicy[] } = {},
): { [key: string]: VendorPolicy[] } => {
  return items.reduce((h, item) => {
    const policiesForVendorId = h[item.vendor_id] || [];
    return { ...h, [item.vendor_id]: [...policiesForVendorId, item] };
  }, entities);
};

export const reducer = createReducer(
  initialState,
  on(Actions.getVendorPoliciesSuccess, (state, { data }) => {
    return {
      ...state,
      policies: reducePolicies(data),
    };
  }),
);
