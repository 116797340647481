import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, SharedEnvironment } from '@chassis/shared/environment';
import { HttpClient } from '@angular/common/http';
import { VendorPolicy } from './policy.model';

@Injectable({
  providedIn: 'root',
})
export class VendorPolicyService {
  constructor(
    @Inject(ENVIRONMENT) private env: SharedEnvironment,
    private httpClient: HttpClient,
  ) {}

  getVendorPolicies() {
    return this.httpClient.get<{ data: VendorPolicy[] }>(
      `${this.env.BASEURL_V4}/vendor_policies`,
    );
  }
}
