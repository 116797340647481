import { createAction, props } from '@ngrx/store';
import { VendorPolicy } from '../policy.model';

export enum VendorPoliciesTypes {
  GetVendorPolicies = '[VENDOR POLICIES] FETCH VENDOR POLICIES',
  GetVendorPoliciesSuccess = '[VENDOR POLICIES] FETCH VENDOR POLICIES SUCCESS',
  GetVendorPoliciesFail = '[VENDOR POLICIES] FETCH VENDOR POLICIES FAIL',
}

export const getVendorPolicies = createAction(
  VendorPoliciesTypes.GetVendorPolicies,
);

export const getVendorPoliciesSuccess = createAction(
  VendorPoliciesTypes.GetVendorPoliciesSuccess,
  props<{ data: VendorPolicy[] }>(),
);

export const getVendorPoliciesFail = createAction(
  VendorPoliciesTypes.GetVendorPoliciesFail,
  props<{ error: string }>(),
);
