import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SharedActions } from '@chassis/shared/actions';
import * as VendorPolicyActions from './vendor-policies.actions';
import { map, of, switchMap } from 'rxjs';
import { VendorPolicyService } from '../vendor-policy.service';

@Injectable()
export class VendorPoliciesEffects {
  private service = inject(VendorPolicyService);
  constructor(private actions$: Actions) {}

  postAuthTriggers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.initPostAuth),
      switchMap(() => of(VendorPolicyActions.getVendorPolicies())),
    ),
  );

  loadVendorPolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VendorPolicyActions.getVendorPolicies),
      switchMap(() => {
        return this.service.getVendorPolicies().pipe(
          map((data) => {
            return VendorPolicyActions.getVendorPoliciesSuccess(data);
          }),
        );
      }),
    ),
  );
}
